import React from 'react'
import Carousel from './Carousel'

function Home() {
  return (
    <div>
      <Carousel />
    </div>
  )
}

export default Home
