import React from "react";

function Work() {
  return (
    <>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/kids-portrait_vbkdzf_c_scale,w_200.png 200w,
            /assets/kids-portrait_vbkdzf_c_scale,w_732.png 732w,
            /assets/kids-portrait_vbkdzf_c_scale,w_890.png 890w,
            /assets/kids-portrait_vbkdzf_c_scale,w_1069.png 1069w,
            /assets/kids-portrait_vbkdzf_c_scale,w_1200.png 1200w"
            src="/assets/kids-portrait_vbkdzf_c_scale,w_1200.png"
            alt="kids-portrait">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/acrylic-lucky_gqbttl_c_scale,w_200.png 200w,
            /assets/acrylic-lucky_gqbttl_c_scale,w_614.png 614w,
            /assets/acrylic-lucky_gqbttl_c_scale,w_882.png 882w,
            /assets/acrylic-lucky_gqbttl_c_scale,w_1056.png 1056w,
            /assets/acrylic-lucky_gqbttl_c_scale,w_1200.png 1200w"
            src="/assets/acrylic-lucky_gqbttl_c_scale,w_1200.png"
            alt="acrylic-lucky">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/watercolor-mom_bewwv4_c_scale,w_200.png 200w,
            /assets/watercolor-mom_bewwv4_c_scale,w_620.png 620w,
            /assets/watercolor-mom_bewwv4_c_scale,w_877.png 877w,
            /assets/watercolor-mom_bewwv4_c_scale,w_1057.png 1057w,
            /assets/watercolor-mom_bewwv4_c_scale,w_1200.png 1200w"
            src="/assets/watercolor-mom_bewwv4_c_scale,w_1200.png"
            alt="watercolor-mom">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/flower-pot_rvtgvb_c_scale,w_200.png 200w,
            /assets/flower-pot_rvtgvb_c_scale,w_601.png 601w,
            /assets/flower-pot_rvtgvb_c_scale,w_862.png 862w,
            /assets/flower-pot_rvtgvb_c_scale,w_1039.png 1039w,
            /assets/flower-pot_rvtgvb_c_scale,w_1200.png 1200w"
            src="/assets/flower-pot_rvtgvb_c_scale,w_1200.png"
            alt="flower-pot">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/acrlic-flowers_ciqhok_c_scale,w_200.png 200w,
            /assets/acrlic-flowers_ciqhok_c_scale,w_937.png 937w,
            /assets/acrlic-flowers_ciqhok_c_scale,w_1162.png 1162w,
            /assets/acrlic-flowers_ciqhok_c_scale,w_1178.png 1178w,
            /assets/acrlic-flowers_ciqhok_c_scale,w_1200.png 1200w"
            src="/assets/acrlic-flowers_ciqhok_c_scale,w_1200.png"
            alt="acrlic-flowers">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/acrylic-pink-flowers_zfkza6_c_scale,w_200.png 200w,
            /assets/acrylic-pink-flowers_zfkza6_c_scale,w_602.png 602w,
            /assets/acrylic-pink-flowers_zfkza6_c_scale,w_863.png 863w,
            /assets/acrylic-pink-flowers_zfkza6_c_scale,w_1046.png 1046w,
            /assets/acrylic-pink-flowers_zfkza6_c_scale,w_1200.png 1200w"
            src="/assets/acrylic-pink-flowers_zfkza6_c_scale,w_1200.png"
            alt="acrylic-pink-flowers">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/acrylic-butterfly_mhjjc1_c_scale,w_200.png 200w,
            /assets/acrylic-butterfly_mhjjc1_c_scale,w_611.png 611w,
            /assets/acrylic-butterfly_mhjjc1_c_scale,w_878.png 878w,
            /assets/acrylic-butterfly_mhjjc1_c_scale,w_1059.png 1059w,
            /assets/acrylic-butterfly_mhjjc1_c_scale,w_1200.png 1200w"
            src="/assets/acrylic-butterfly_mhjjc1_c_scale,w_1200.png"
            alt="acrylic-butterfly">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/waves-pastel_kohqnm_c_scale,w_200.png 200w,
            /assets/waves-pastel_kohqnm_c_scale,w_610.png 610w,
            /assets/waves-pastel_kohqnm_c_scale,w_875.png 875w,
            /assets/waves-pastel_kohqnm_c_scale,w_1046.png 1046w,
            /assets/waves-pastel_kohqnm_c_scale,w_1200.png 1200w"
            src="/assets/waves-pastel_kohqnm_c_scale,w_1200.png"
            alt="waves-pastel">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/abstract-coral_jkxgth_c_scale,w_200 200w,
            /assets/abstract-coral_jkxgth_c_scale,w_731.png 731w,
            /assets/abstract-coral_jkxgth_c_scale,w_883.png,
            /assets/abstract-coral_jkxgth_c_scale,w_1065.png,
            /assets/abstract-coral_jkxgth_c_scale,w_1200.png"
            src="/assets/abstract-coral_jkxgth_c_scale,w_1200.png"
            alt="acrlic-flowers">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/tree_k2yiwb_c_scale,w_200.png 200w,
            /assets/tree_k2yiwb_c_scale,w_704.png 704w,
            /assets/tree_k2yiwb_c_scale,w_1024.png 1024w,
            /assets/tree_k2yiwb_c_scale,w_1103.png 1103w,
            /assets/tree_k2yiwb_c_scale,w_1200.png 1200w"
            src="/assets/tree_k2yiwb_c_scale,w_1200.png"
            alt="abstract tree">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/trees-pastel_dwjf8m_c_scale,w_200.png 200w,
            /assets/trees-pastel_dwjf8m_c_scale,w_604.png 604w,
            /assets/trees-pastel_dwjf8m_c_scale,w_862.png 862w,
            /assets/trees-pastel_dwjf8m_c_scale,w_1048.png 1048w,
            /assets/trees-pastel_dwjf8m_c_scale,w_1200.png 1200w"
            src="/assets/trees-pastel_dwjf8m_c_scale,w_1200.png"
            alt="trees">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/watercolor-flower_aqbtzb_c_scale,w_200.png 200w,
            /assets/watercolor-flower_aqbtzb_c_scale,w_754.png 754w,
            /assets/watercolor-flower_aqbtzb_c_scale,w_888.png 888w,
            /assets/watercolor-flower_aqbtzb_c_scale,w_1083.png 1083w,
            /assets/watercolor-flower_aqbtzb_c_scale,w_1200.png 1200w"
            src="/assets/watercolor-flower_aqbtzb_c_scale,w_1200.png"
            alt="watercolor-flower">
          </img>
        </figure>
      </div>
      <div class="card card-bordered">
        <figure>
          <img
            sizes="(max-width: 1200px) 100vw, 1200px"
            srcset="
            /assets/watercolor-road_ppjfcr_c_scale,w_200.png 200w,
            /assets/watercolor-road_ppjfcr_c_scale,w_1151.png 1151w,
            /assets/watercolor-road_ppjfcr_c_scale,w_1199.png 1199w,
            /assets/watercolor-road_ppjfcr_c_scale,w_1200.png 1200w"
            src="/assets/watercolor-road_ppjfcr_c_scale,w_1200.png"
            alt="watercolor-road">
          </img>
        </figure>
      </div>
    </>
  );
}

export default Work;
